/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */
import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import { StrictMode, startTransition, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";

if (window.ENV.SENTRY_STAGE !== "local") {
  Sentry.init({
    environment: window.ENV.SENTRY_STAGE,
    dsn: window.ENV.SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches,
      }),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Reducing sample rate due to potentially causing issues by spamming preflight requests
    // https://docs.sentry.io/platforms/javascript/troubleshooting/#unexpected-options-request
    tracesSampleRate: 0.1,
    tracePropagationTargets: [/^https:\/\/apeiron\.[a-z]*\.infinitystudio\.ai/],

    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: ["Expected fetch controller: "],
  });
}

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  );
});
